import React from "react"
import SolutionsLayout from "../../components/SolutionsLayout"
import { Grid } from "@material-ui/core"
import Card from "../../components/Card"

export default function Migration() {
  return (
    <SolutionsLayout>
      <h2 className="heading-2 left">Migration</h2>
      <p className="paragraph left">
        <strong className="bold-text">
          Moving to the cloud is no longer trendy, it's essential!
          <br />
          <br />
          Migrating your existing applications and IT assets to the cloud
          presents an opportunity to transform the way your organization does
          business. It can help you lower costs, become more agile, and deliver
          reliable globally available services to your customers. Our goal is to
          help you to implement your cloud strategy successfully.{" "}
        </strong>
        <br />
        <br />
        Migrating your existing systems to Cloud doesn’t require an overhaul to
        your code base. Getting you to the cloud quickly, securely, and cost
        effectively is what Cloud3S does. Our holistic approach to cloud
        migration ensures everything is covered.
        <br />
        Our strategy is to quickly provide cost and performance benefits by
        identifying those systems which would have the greatest impact.
        <br />
        <br />
        Most systems can be done in a “lift-and-shift” manner – simply migrating
        the application as is. While this can be a straightforward execution, it
        doesn’t take advantage of the capabilities of the cloud.
        <br />
        Cloud3S understands how various services can be brought into play to
        vastly improve your systems’ performance.
        <br />
        <br />
        Our Cloud engineers help you with migrating your applications from on
        premises or colo infrastructure to the cloud. We provide assistance with
        migration large datasets in the fastest and most economical way. Our
        engineers will make changes to the application to ensure it responds
        well to automated scaling and is fault tolerant.
        <br />
        <br />
        Our team knows that just identifying the right cloud approach is not
        enough for success, migrating your applications and environments in the
        correct order equally plays a vital role in making the cloud migration
        successful. Hence, when you choose Cloud3S to be your cloud migration
        partner, you can be rest assured that our comprehensive cloud migration
        services ensure fast, cost-effective and successful transition with zero
        adverse impact on your business.
        <br />
        <br />
      </p>
      <h2 className="heading-2 left">Benefits</h2>
      <ul className="list">
        <li>
          Increased <b>scalability</b> to meet increasing demand on your
          applications
        </li>
        <li>
          Moving to the cloud will reduce your <b>operational costs</b> while
          increasing the effectiveness of your IT processes.
        </li>
        <li>
          Faster <b>time to market</b>
        </li>
        <li>Enhanced security features and controls</li>
        <li>
          Increased <b>mobility</b>: Onboard your product in a new market in a
          matter of hours
        </li>
        <li>
          Pay-as-You-Go Model: Spend just the right amount of money at the right
          time
        </li>
        <li>
          Improved <b>cost management</b>, track exactly where your money is
          going
        </li>
        <li>Reduced infrastructure complexity</li>
        <li>
          Improved <b>productivity</b> for your teams, freeing up time for more
          rewarding projects
        </li>
        <li>Near limitless capacity to grow your business</li>
        <li>
          Built-in <b>backup</b> and <b>disaster recovery</b> processes
        </li>
      </ul>
      <h2 className="heading-2 left">What we offer</h2>
      <Grid container alignItems="stretch" className={"w-container"}>
        <Grid item xs={12} lg={6}>
          <Card title="Strategy and assessment" icon={1}>
            Our service involves workloads’ assessment to identify
            cloud-readiness for application migration.
            <br />
            <br />
            The tested & proven methodology is customized to enhance business
            outcomes and overall results.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Cloud Migration" icon={18}>
            We offer solutions that ease the process of application migration
            and modernization, enable building new cloud-native applications,
            and transform your infrastructure.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Governance" icon={5}>
            Post-migration of workloads to the cloud, we ensure that you are
            able to maximize value through cloud investments. Our cloud
            governance frameworks are unique to your business value creation,
            benefits, and resource optimization.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Validation and performance testing" icon={15}>
            We work closely with your engineers to ensure your migrated
            workloads meet current and future business needs
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Security assessment" icon={12}>
            Ensures that the workloads deployed or developed in the cloud align
            to the organization’s security control, resiliency, and compliance
            requirements.
          </Card>
        </Grid>
      </Grid>
      <br />
      <h2 className="heading-2 left">Why partner with us</h2>

      <ul className="list">
        <li>
          We offer you a perfect blend of flexibility and responsiveness. Our
          proven track record and vast real-time experience will help you
          realize a safe and relatively nimble cloud transition.
        </li>
        <li>Secure and Compliant</li>
        <li>Our Expertise Help You Unlock The Full Potential Of Cloud</li>
        <li>
          More than a decade of expertise in cloud computing (AWS, Azure, GCP
          and Hybrid)
        </li>
        <li>
          Customized migration solution according to your business goals,
          budget, and project requirement
        </li>
        <li>
          Continuous monitoring, backup and logging services for safety of
          critical information
        </li>
        <li>
          Our expertise in cloud migration will make your transition faster and
          efficient. We follow an agile and scalable approach toward cloud
          migration that will help you focus on innovation and enhancing
          competitive capabilities in real-time.
        </li>
      </ul>
    </SolutionsLayout>
  )
}
